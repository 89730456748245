import Onboard from 'bnc-onboard'
import Notify from 'bnc-notify'

const networkId = 1
const rpcUrl = 'wss://mainnet.infura.io/ws/v3/1e4227efe6c44a10be8fec7db4c9b168'
// const apiUrl = 'wss://mainnet.infura.io/ws/v3/1e4227efe6c44a10be8fec7db4c9b168'
export const apiUrl =
  'wss://eth-mainnet.ws.alchemyapi.io/v2/M5TMcUgk2pd57qGyWhfzSY0BJ1oAjqmm'
export const rpcURL =
  'https://eth-mainnet.alchemyapi.io/v2/M5TMcUgk2pd57qGyWhfzSY0BJ1oAjqmm'
const dappId = 'e9c1b522-7ac6-4868-8a28-0cdbdcaa958a'

export function notify() {
  return Notify({
    dappId, // [String] The API key created by step one above
    networkId: 4, // [Integer] The Ethereum network ID your Dapp uses.
  })
}

export function initOnboard(subscriptions) {
  const onboard = Onboard
  return onboard({
    dappId,
    hideBranding: false,
    networkId,
    apiUrl,
    // darkMode: true,
    subscriptions,
    walletSelect: {
      wallets: [{ walletName: 'metamask' }],
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' },
      { checkName: 'network' },
      { checkName: 'balance', minimumBalance: '100000' },
    ],
  })
}


// export function initNotify() {
//   const notify = Notify
//   return notify({
//     dappId,
//     networkId,
//     apiUrl,
//   })
// }
