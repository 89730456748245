import { useState, useEffect } from 'react'
import { initOnboard, notify } from './services'
import { ethers } from 'ethers'
import constate from 'constate'
import { NFT_ABI } from './ABI/NFT.js'
import { TOKEN_ABI } from './ABI/ERC20.js'
import getSigner from './signer.js'
import { useToast } from '@chakra-ui/react'


const appNetwork = process.env.NODE_ENV == 'development' ? '1' : '1'

function useWeb3() {
  const toast = useToast()

  const defaultContractState = {
    NFT: null,
    ERC20: null,
  }
  const [provider, setProvider] = useState(null)
  const [address, setAddress] = useState(null)
  const [network, setNetwork] = useState(null)
  const [balance, setBalance] = useState(null)
  const [wallet, setWallet] = useState(null)
  const [contract, setContract] = useState(defaultContractState)
  const [readProvider, setReadProvider] = useState(null)
  // const [appNotify, setNotify] = useState(null)
  const [onboard, setOnboard] = useState(null)
  useEffect(() => {
    const onboard = initOnboard({
      address: setAddress,
      network: setNetwork,
      balance: setBalance,
      wallet: (wallet) => {
        if (wallet.provider) {
          setWallet(wallet)

          const ethersProvider = new ethers.providers.Web3Provider(
            wallet.provider
          )

          window.localStorage.setItem('selectedWallet', wallet.name)
          setProvider(ethersProvider)
        } else {
          setProvider(null)
          setWallet({})
        }
      },
    })

    setOnboard(onboard)

    // setNotify(initNotify())
  }, [])

  useEffect(() => {
    const loadReadProvider = () => {
      const rpcReadProvider = ethers.getDefaultProvider('mainnet', {
        alchemy: 'BzJfQ9KGENwP7o9XoN3_GQVTpDYwOcLm',
      })
      setReadProvider(rpcReadProvider)
    }

    loadReadProvider()
  }, [])

  useEffect(() => {
    const createContracts = () => {
      const NFTAddress = '0x7F96F68Fa766D4E9b037417FE4967511152B9272'
      const ERC20Address = '0x8872332b988152D485028dDB68E7Da98962958cd'
      const NFT = new ethers.Contract(NFTAddress, NFT_ABI, getSigner(provider))

      const ERC20 = new ethers.Contract(
        ERC20Address,
        TOKEN_ABI,
        getSigner(provider)
      )

      setContract({ NFT, ERC20 })
    }

    if (provider && network == appNetwork) {
      createContracts()
    } else if (provider && network && network != appNetwork) {
      toast({
        title: 'Incorrect Network',
        description: `Your Web3 provider is on an incorrect network. This app works on ${networkName(
          appNetwork
        )}. Please change network and reload the page.`,
        status: 'warning',
        duration: 90000,
        isClosable: true,
      })
    }
  }, [provider, network])

  const isWalletConnected = async () => {
    return await onboard.walletCheck()
  }
  const connect = async () => {
    await onboard.walletSelect()
    await onboard.walletCheck()
  }

  useEffect(() => {
    const previouslySelectedWallet = window.localStorage.getItem(
      'selectedWallet'
    )

    if (previouslySelectedWallet && onboard) {
      onboard.walletSelect(previouslySelectedWallet)
    }
  }, [onboard])

  return {
    isWalletConnected,
    connect,
    notify,
    readProvider,
    provider,
    address,
    network,
    balance,
    wallet,
    onboard,
    contract,
    networkName,
    appNetwork,
  }
}

// 2️⃣ Wrap your hook with the constate factory
export const [Web3ContextProvider, useWeb3Context] = constate(useWeb3)

export function networkName(id) {
  switch (Number(id)) {
    case 1:
      return 'main'
    case 3:
      return 'ropsten'
    case 4:
      return 'rinkeby'
    case 5:
      return 'goerli'
    case 42:
      return 'kovan'
    case 'localhost':
      return 'localhost'
    default:
      return 'local'
  }
}
