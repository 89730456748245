// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      <Route path="/address" page={AddressPage} name="address" />
      <Route path="/governance" page={GovernancePage} name="governance" />
      <Route path="/solos" page={SolosPage} name="solos" />
      <Route path="/attribute" page={AttributePage} name="attribute" />
      <Route path="/single-token/{id}" page={SingleTokenPage} name="singleToken" />
      <Route path="/saturnalia" page={SaturnaliaPage} name="saturnalia" />
      <Route path="/purchase" page={PurchasePage} name="purchase" />
      <Route path="/image/{id}" page={ImagePage} name="image" />
      <Route path="/gallery" page={GalleryPage} name="gallery" />
      <Route path="/about" page={AboutPage} name="about" />
      <Route path="/" page={HomePage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
