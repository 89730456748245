import { FatalErrorBoundary } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { Fonts } from './Fonts/Fonts'
import { Web3ContextProvider } from './common/useWeb3'
import { QueryClientProvider, QueryClient } from 'react-query'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

// import './index.css'

const theme = extendTheme({
  fonts: {
    heading: 'Open Sans',
    body: 'Raleway',
  },
})

const queryClient = new QueryClient()

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodApolloProvider>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Web3ContextProvider>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </Web3ContextProvider>
      </ChakraProvider>
    </RedwoodApolloProvider>
  </FatalErrorBoundary>
)

export default App
